export class BC_Products {
  constructor() {
    this.Products250 = [
      // Row 1
      { slug: 'white-gold-250', name1: 'White Gold', code: 'LDCC250', grams: 250, gridColumn: 1, gridRow: 1 },
      { slug: 'creamed-honey-275', name1: 'Vegan Creamed Honey', code: 'VCH275', grams: 275, gridColumn: 2, gridRow: 1 },
      { slug: 'hazelnut-275', name1: 'Hazelnut Chocolate Spread', code: 'HCS275', grams: 275, gridColumn: 3, gridRow: 1 },
      { slug: 'tiramisu-ish-250', name1: 'Tiramisu-ish', code: 'T250', grams: 250, gridColumn: 4, gridRow: 1 },
      { slug: 'butter-250', name1: 'Cultured Vegan Butter', code: 'CVB250', grams: 250, gridColumn: 5, gridRow: 1 },
      { slug: 'black-gold-250', name1: 'Cultured Black Gold', code: 'CBG250', grams: 250, gridColumn: 6, gridRow: 1 },
      // Row 2
      {
        slug: 'persian-feta-275',
        name1: 'Persian Style',
        name2: 'Macadamia Feta',
        code: 'PSMF275',
        grams: 275,
        gridColumn: 1,
        gridRow: 2,
      },
      { slug: 'pesto-250', name1: 'Basil & Kale Pesto', code: 'BKP250', grams: 250, gridColumn: 2, gridRow: 2 },
      {
        slug: 'beetroot-250',
        name1: 'Caramalised Beetroot',
        name2: 'Fermented Cashew Dip',
        code: 'CBFCD250',
        grams: 250,
        gridColumn: 3,
        gridRow: 2,
      },
      {
        slug: 'pomegranate-275',
        name1: 'Pomegranate Mint',
        name2: 'Macadamia Feta',
        code: 'PMMF275',
        grams: 275,
        gridColumn: 4,
        gridRow: 2,
      },
      { slug: 'classic-250', name1: 'Classic Cashew Cheese', code: 'CCC250', grams: 250, gridColumn: 5, gridRow: 2 },
      { slug: 'lemon-and-dill-250', name1: 'Lemon & Dill', code: 'LDCC250', grams: 250, gridColumn: 6, gridRow: 2 },
      // Row 3
      { slug: 'new-york-250', name1: 'New Yorker Cashew Cheese', code: 'NYCC250', grams: 250, gridColumn: 1, gridRow: 3 },
      { slug: 'mushroom-250', name1: 'Mushroom Truffle Cashew Cheese', code: 'MTCC250', grams: 250, gridColumn: 2, gridRow: 3 },
      { slug: 'turmeric-250', name1: 'Turmeric Cashew Cheese', code: 'TCC250', grams: 250, gridColumn: 3, gridRow: 3 },
      { slug: 'japanese-250', name1: 'Japanese Fermented Slaw', code: 'JFS250', grams: 250, gridColumn: 4, gridRow: 3 },
      { slug: 'korean-250', name1: 'Korean Fermented Slaw', code: 'KFS250', grams: 250, gridColumn: 5, gridRow: 3 },
      { slug: 'nutty-frank-250', name1: 'Nutty Frank Fermented Slaw', code: 'NFFS250', grams: 250, gridColumn: 6, gridRow: 3 },
    ];
    this.Products1000 = [
      // Row 1
      {
        slug: 'persian-feta-1000',
        name1: 'Persian Style',
        name2: 'Macadamia Feta',
        code: 'FSPSMF1000',
        grams: 1000,
        gridColumn: 1,
        gridRow: 1,
      },
      { slug: 'butter-1000', name1: 'Cultured Vegan Butter', code: 'FSCVB1000', grams: 1000, gridColumn: 2, gridRow: 1 },
    ];

    this.Products1375 = [
      // Row 1
      { slug: 'butter-1375', name1: 'Cultured Vegan Butter', code: 'FSVBC1375', grams: 1375, gridColumn: 1, gridRow: 1 },
      {
        slug: 'persian-feta-1375',
        name1: 'Persian Style',
        name2: 'Macadamia Feta',
        code: 'FSPSMF1375',
        grams: 1375,
        gridColumn: 2,
        gridRow: 1,
      },
      { slug: 'pesto-1375', name1: 'Basil & Kale', name2: 'Pesto', code: 'FSBKPF1375', grams: 1375, gridColumn: 3, gridRow: 1 },
    ];
  }

  /// Retreive a product info hash using its
  /// "slug" identifier
  getBySlug(slug) {
    let matches;

    matches = this.Products250.filter((product) => product.slug === slug);
    if (matches.length == 1) {
      return matches[0];
    }
    matches = this.Products1000.filter((product) => product.slug === slug);
    if (matches.length == 1) {
      return matches[0];
    }
    matches = this.Products1375.filter((product) => product.slug === slug);
    if (matches.length == 1) {
      return matches[0];
    }
    console.error(`Invalid slug: ${slug}`, matches);
    return null;
  }
}
