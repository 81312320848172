export class BC_DisplayController {
  constructor() {
    this.oldZoom = -1;
    this.isZoomed = false;
    this.aspect = "no-aspect";
    this.zoom = 1;
    this.PORTRAIT = "portrait";
    this.LANDSCAPE = "landscape";
    this.onViewChangeCallback = null;
    this.oldViewHeight = window.innerHeight;
    this.onHeightChangeCallback = null;
  }

  onReady(onViewChangeCallback = null) {
    this.updateViewHeight();
    setInterval(() => this.tick(), 500);
    this.onViewChangeCallback = onViewChangeCallback;
  }

  setHeightChangeCallback(callback) {
    this.onHeightChangeCallback = callback;
  }

  updateViewHeight() {
    let jsViewHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      "--jsViewHeight",
      `${jsViewHeight}px`
    );
    // adjust scroll position proportionately to view height change
    if (this.oldViewHeight != window.innerHeight) {
      const multiplier = window.innerHeight / this.oldViewHeight;
      if (this.onHeightChangeCallback) {
        this.onHeightChangeCallback(multiplier);
      }
      this.oldViewHeight = window.innerHeight;
    }
  }

  tick() {
    // Figure out zoom
    this.zoom = document.documentElement.clientWidth / window.innerWidth;
    const isZoomed = this.zoom > 1;
    if (isZoomed !== this.isZoomed) {
      this.isZoomed = isZoomed;
      $("body").toggleClass("zoomed", this.isZoomed);
    }

    // Figure out aspect
    const newAspectRatio =
      document.documentElement.clientWidth /
      document.documentElement.clientHeight;
    if (newAspectRatio != this.aspectRatio) {
      this.aspectRatio = newAspectRatio;
      //       console.log('New ratio: ' + this.aspectRatio);
      const newAspect = this.aspectRatio < 1 ? this.PORTRAIT : this.LANDSCAPE;
      if (newAspect != this.aspect) {
        this.aspect = newAspect;
        $("body").toggleClass("portrait", this.aspect === this.PORTRAIT);
        $("body").toggleClass("landscape", this.aspect === this.LANDSCAPE);
        if (this.onViewChangeCallback) {
          this.onViewChangeCallback();
        }
      }
    }

    // Figure out view height changes
    this.updateViewHeight();
  }
}
