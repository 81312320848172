import "core-js/stable";
import "regenerator-runtime/runtime";

import { BC_AutoTextFitter } from "./bc-auto-text-fitter";
import { BC_AVTools } from "./bc-av-tools";
import { BC_ImagePreloader } from "./bc-image-preloader";
import { slidesetInit } from "./bc-slideset";
import { BC_Products } from "./bc-products";
import { BC_Slideshow } from "./bc-slideshow";
// import { BC_Scroller } from './bc-scroller';
import { BC_DisplayController } from "./bc-display-controller";

const DRAFT_VERSION = ""; // "DRAFT: 21 August 2020";

const autoTextFitter = new BC_AutoTextFitter();
const imagePreloader = new BC_ImagePreloader();
const avTools = new BC_AVTools();
const displayController = new BC_DisplayController();
const products = new BC_Products();
const slideset = slidesetInit(
  imagePreloader,
  autoTextFitter,
  avTools,
  displayController,
  products
);
const slideshow = new BC_Slideshow(
  slideset,
  autoTextFitter,
  imagePreloader,
  avTools,
  displayController
);
/*
const scroller = new BC_Scroller(
  displayController,
  autoTextFitter,
  () => slideshow.showPrevSlide(),
  () => slideshow.showNextSlide()
);
*/

/// Initialize everything
$(function () {
  if (DRAFT_VERSION == "") {
    $(".draft-version").hide();
  } else {
    $(".draft-version").text(DRAFT_VERSION);
  }

  displayController.onReady(() => slideshow.handleViewResize());
  slideshow.onReady();

  // setTimeout(() => {
  //   addClickEventsForProductsPage('#products-250g', Products250);
  //   addClickEventsForProductsPage('#products-1000g', Products1000);
  //   addClickEventsForProductsPage('#products-1375g', Products1375);
  // }, 0);
  // Click back from anywhere

  $(document).keydown((e) => {
    // First up, don't mess with arrows if a form field is in focus
    if (
      slideshow.ActiveRow == 2 &&
      slideshow.ActiveColumn == 1 &&
      $("#enquiries input").is(":focus")
    ) {
      return;
    }
    // left arrow acts as "back" button on most sub-pages
    if (
      slideshow.ActiveColumn > 0 &&
      e.which === 37 &&
      slideshow.ActiveColumn > 0
    ) {
      slideshow.showSlide(slideshow.ActiveRow, 0);
      slideshow.triggerAutoScrollNowOrAfterUserScroll();
      e.preventDefault(); // prevent the default action (scroll / move caret)
      return;
    }
    // // up in main page
    // if (slideshow.ActiveColumn === 0 && e.which === 38) {
    //   slideshow.showSlide(slideshow.ActiveRow - 1, 0);
    //   slideshow.triggerAutoScrollNowOrAfterUserScroll();
    //   e.preventDefault(); // prevent the default action (scroll / move caret)
    //   return;
    // }
    // // down in main page
    // if (slideshow.ActiveColumn === 0 && e.which === 40) {
    //   slideshow.showSlide(slideshow.ActiveRow + 1, 0);
    //   slideshow.triggerAutoScrollNowOrAfterUserScroll();
    //   e.preventDefault(); // prevent the default action (scroll / move caret)
    //   return;
    // }
  });

  // add zoom
  // $("img.bottom-view").lightzoom({ zoomPower: 4, glassSize: 250 });

  setTimeout(() => {
    slideshow.setupProductClickHandlers();
    slideshow.showSlide(0, 0);
  });
});
