export class BC_Slide {
  constructor(preloader, autoTextFitter, avTools, displayController, settings) {
    this.preloader = preloader;
    this.autoTextFitter = autoTextFitter;
    this.avTools = avTools;
    this.displayController = displayController;
    if (!settings || settings.row == null || settings.col == null) {
      throw new Error("invalid slide settings", settings);
    }
    this.id = settings.id;
    this.row = settings.row;
    this.column = settings.col;
    this.preloads = settings.preload == null ? [] : settings.preload;
    this.products = settings.products == null ? [] : settings.products;
    this.autoFitterRanWithWidth = -1;
    this.autoFitterRanWithHeight = -1;
    this.autoFitterRanWithOrientation = -10000;
    this.imagePreloaderRan = false;
  }

  onReady(slideshow) {
    this.slideshow = slideshow;
    // back to start of row
    $(".back-arrow").click(() => {
      console.log("Back clicked");
      this.slideshow.showSlide(this.slideshow.ActiveRow, 0);
    });
  }

  windowWidth() {
    return $("#reference-size-area").width();
    // return screen.width;
    // return document.documentElement.clientWidth;
    // return window.innerWidth;
  }
  windowHeight() {
    return $("#reference-size-area").height();
    // return screen.height;
    // return document.documentElement.clientHeight;
    // return window.innerHeight;
  }
  windowOrientation() {
    return window.orientation;
  }

  prepareForOnscreen() {
    const slideSelector = this.getSelector();
    // console.log('Preparing slide for onscreen: ' + slideSelector);
    if (
      this.autoFitterRanWithHeight != this.windowHeight() ||
      this.autoFitterRanWithOrientation != this.windowOrientation()
    ) {
      console.log("Autofitter height change: " + this.windowHeight());
      this.autoTextFitter.autoHeightFitChildren(slideSelector);
      this.autoTextFitter.autoFitChildrenLetterSpacing(slideSelector);
    }
    if (
      this.autoFitterRanWithWidth != this.windowWidth() ||
      this.autoFitterRanWithOrientation != this.windowOrientation()
    ) {
      console.log("Autofitter width change: " + this.windowWidth());
      this.autoTextFitter.autoHeightFitChildren(slideSelector);
      this.autoTextFitter.autoFitChildrenLetterSpacing(slideSelector);
    }
    this.autoFitterRanWithHeight = this.windowHeight();
    this.autoFitterRanWithWidth = this.windowWidth();
    this.autoFitterRanWithOrientation = this.windowOrientation();
    if (!this.imagePreloaderRan) {
      // console.log('- preload images for: ' + slideSelector);
      this.preloadImages();
      this.imagePreloaderRan = true;
    }
  }

  setupClickHandlers(onViewProduct) {
    // Set up events for products list
    /// Set up event handlers for products on a page
    this.products.forEach((product) => {
      const slug = product.slug;
      $(`${this.getSelector()} .view-product-${slug}`).click(() => {
        onViewProduct(product);
      });
    });
  }

  getSelector() {
    if (this.id) {
      return `#${this.id}`;
    }
    // fall back on selecting by row and column
    return `#rows-container div:nth-of-type(${1 + this.row}) div:nth-of-type(${
      1 + this.column
    })`;
  }

  preloadImages() {
    return this.preloader.preloadImageSet(this.preloads);
  }

  /// Give a little "bounce" animation to show
  /// user they've gone past the top/bottom
  bounce(topAmount) {
    const jqElement = $(this.getSelector());
    jqElement.animate({ "margin-top": topAmount }, 200, () => {
      jqElement.animate({ "margin-top": "0" }, 100);
    });
  }

  setTextElement(selectorWithinSlide, newTextValue) {
    if (newTextValue === undefined || newTextValue === null) {
      newTextValue = "";
    }
    const slideSelector = this.getSelector();
    $(`${slideSelector} ${selectorWithinSlide}`).text(newTextValue);
  }
}
