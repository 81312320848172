import { BC_Slide } from "./bc-slide";
import { BC_Slide_Landing } from "./bc-slide-landing";
import { BC_Slide_Enquiries } from "./bc-slide-enquiries";
import { BC_Slide_ProductGrid } from "./bc-slide-productgrid";
import { BC_Slide_ProductDetail } from "./bc-slide-productdetail";
import { BC_Slide_NASAA } from "./bc-slide-nasaa";
import { BC_Slide_Enquiries_Form } from "./bc-slide-enquiries-form";
import { BC_Slide_Enquiries_Thanks } from "./bc-slide-enquiries-thanks";

export function slidesetInit(
  preloader,
  autoTextFitter,
  avTools,
  displayController,
  products
) {
  let row00_landing = [
    new BC_Slide_Landing(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        id: "landing-page-slide",
        row: 0,
        col: 0,
        preload: {
          "landingPage-black-landscape": "img/landingPage-black-landscape.jpg",
          "landingPage-white-landscape": "img/landingPage-white-landscape.jpg",
          "landingPage-black-portrait": "img/landingPage-black-portrait.jpg",
          "landingPage-white-portrait": "img/landingPage-white-portrait.jpg",
        },
      }
    ),
  ];

  let row01_nasaa = [
    new BC_Slide_NASAA(preloader, autoTextFitter, avTools, displayController, {
      id: "nasaa-slide",
      row: 1,
      col: 0,
    }),
  ];

  let row02_bc_original = [
    new BC_Slide(preloader, autoTextFitter, avTools, displayController, {
      id: "bc-original",
      row: 2,
      col: 0,
      preload: {
        "enzyme-rich-portrait": "img/enzyme-rich-portrait.jpg",
        "enzyme-rich-landscape": "img/enzyme-rich-landscape.jpg",
      },
    }),
  ];

  let row03_fermented = [
    new BC_Slide(preloader, autoTextFitter, avTools, displayController, {
      row: 3,
      col: 0,
    }),
  ];

  let row04_processing = [
    new BC_Slide(preloader, autoTextFitter, avTools, displayController, {
      row: 4,
      col: 0,
      preload: {
        "processing-portrait": "img/processing-and-packaging-portrait.svg",
        "processing-landscape": "img/processing-and-packaging-landscape.svg", 
      },
    }),
  ];

  let row05_enquiries = [
    new BC_Slide_Enquiries(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        id: "enquiries-intro-slide",
        row: 5,
        col: 0,
        preload: {
          "enquiries-diagram-portrait": "img/enquiries-diagram-portrait.svg",
          "enquiries-diagram-landscape": "img/enquiries-diagram-landscape.svg",
        },
      }
    ),
    new BC_Slide_Enquiries_Form(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        id: "enquiries-form-slide",
        row: 5,
        col: 1,
        preload: {
          "contact-form-landscape-top": "img/contact-form-top.svg",
          "contact-form-landscape-submit": "img/contact-form-submit.svg",
          "contact-form-portrait-top": "img/contact-form-top-portrait.svg",
          "contact-form-portrait-submit":
            "img/contact-form-submit-portrait.svg",
          "back-arrow": "img/back-arrow.svg",
        },
      }
    ),
    new BC_Slide_Enquiries_Thanks(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        id: "enquiries-thanks-slide",
        row: 5,
        col: 2,
        preload: {
          "back-arrow": "img/back-arrow.svg",
          thankyou: "img/thankyou.jpg",
          'thankyou-portrait': "img/PORTRAIT-thankyou.jpg",
          'thankyou-landscape': "img/LANDSCAPE-thankyou.jpg",
        },
      }
    ),
  ];

  let row06_stockists = [
    new BC_Slide(preloader, autoTextFitter, avTools, displayController, {
      id: "stockists",
      row: 6,
      col: 0,
      preload: {
        "stockists-map-landscape": "img/stockists-map.svg",
        "stockists-map-landscape2": "img/stockists-map2.svg",
        "stockists-map-portrait": "img/stockists-map-portrait.svg",
      },
    }),
  ];

  let row07_products_250g = [
    new BC_Slide_ProductGrid(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        row: 7,
        col: 0,
        preload: {},
        products: products.Products250,
      }
    ),
    new BC_Slide_ProductDetail(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        row: 7,
        col: 1,
        preload: { "back-arrow": "img/back-arrow.svg" },
      }
    ),
  ];

  let row08_products_1000g = [
    new BC_Slide_ProductGrid(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        row: 8,
        col: 0,
        preload: {},
        products: products.Products1000,
      }
    ),
    new BC_Slide_ProductDetail(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        row: 8,
        col: 1,
        preload: { "back-arrow": "img/back-arrow.svg" },
      }
    ),
  ];

  let row09_products_1375g = [
    new BC_Slide_ProductGrid(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        row: 9,
        col: 0,
        preload: {},
        products: products.Products1375,
      }
    ),
    new BC_Slide_ProductDetail(
      preloader,
      autoTextFitter,
      avTools,
      displayController,
      {
        row: 9,
        col: 1,
        preload: { "back-arrow": "img/back-arrow.svg" },
      }
    ),
  ];

  let slides = [
    row00_landing,
    row01_nasaa,
    row02_bc_original,
    row03_fermented,
    row04_processing,
    row05_enquiries,
    row06_stockists,
    row07_products_250g,
    row08_products_1000g,
    row09_products_1375g,
  ];

  // Check I haven't stuffed up rows or columns
  for (let row = 0; row < slides.length; row++) {
    for (let column = 0; column < slides[row].length; column++) {
      if (
        slides[row][column].row != row ||
        slides[row][column].column != column
      ) {
        throw new Error(
          `Slide on [${row}][${column}] mismatches position [${slides[row][column].row}][${slides[row][column].column}]`
        );
      }
    }
  }

  return slides;
}
