export class BC_ImagePreloader {
  constructor() {
    this.Preloader_images = {};
  }

  // preloads are for a specific slide and of the format
  // {htmlClass1: imageSrc1, htmlClass2: imageSrc2, ...}
  // eg: {'contact-form-top': 'img/contact-form-top.svg'}
  preloadImageSet(classesAndSources) {
    let promises = [];
    // console.log(`Slide has ${classesAndSources == null ? null : Object.keys(classesAndSources)} preloads`);
    if (classesAndSources) {
      for (let htmlClass in classesAndSources) {
        promises.push(this.loadImage(htmlClass, classesAndSources[htmlClass]));
      }
    }
    return Promise.all(promises);
  }

  /// Preload a single image now (sets src attribute for anything matching selector)
  /// Promise resolves when image load attempt is done
  loadImage(htmlClass, imageSrc) {
    if (!this) {
      console.error('No this 1');
    }

    return new Promise((resolve, reject) => {
      // console.log(`Load ${htmlClass}: ${imageSrc}`);

      // No image loaded yet
      if (!this) {
        console.error('No this 2');
      }
      if (!this.Preloader_images[htmlClass]) {
        // console.log(`Actual load needed for ${htmlClass}: ${imageSrc}`);
        this.Preloader_images[htmlClass] = { img: new Image(), src: imageSrc };
        this.Preloader_images[htmlClass].img.onload = () => {
          resolve(htmlClass);
        };
        this.Preloader_images[htmlClass].img.onerror = (err) => {
          console.error(`Error loading ${imageSrc}`, err);
          // resolve(htmlClass);
          reject(htmlClass);
        };
        this.Preloader_images[htmlClass].img.src = imageSrc;
        $(`.${htmlClass}`).attr('src', this.Preloader_images[htmlClass].src);
      } // Desired image src has changed
      else if (this.Preloader_images[htmlClass] && this.Preloader_images[htmlClass].src != imageSrc) {
        // console.log(`Updating src from ${this.Preloader_images[htmlClass].src} to ${imageSrc}`);
        this.Preloader_images[htmlClass].src = imageSrc;
        this.Preloader_images[htmlClass].img.src = imageSrc;
        $(`.${htmlClass}`).attr('src', this.Preloader_images[htmlClass].src);
      } // already have image
      else {
        // console.log(`Didn't need to Load ${htmlClass}: ${imageSrc}`);
        resolve(htmlClass);
      }
    });
  }
}
