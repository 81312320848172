import { BC_Slide } from './bc-slide';

export class BC_Slide_Landing extends BC_Slide {
  constructor(preloader, autoTextFitter, avTools, displayController, settings) {
    super(preloader, autoTextFitter, avTools, displayController, settings);
  }

  onReady(slideshow) {
    super.onReady(slideshow);
    // Landing page click effect
    console.log('Landing page selector: ' + this.getSelector());
    $(this.getSelector()).click(() => {
      console.log('Playing audio');
      this.avTools.playAudio('click-audio');
      console.log('Playing audio returned');
      setTimeout(() => {
        $(this.getSelector()).toggleClass('clicked');
      }, 350);
    });
  }
}
