const STATE_READY = 'ready';
const STATE_COMPLETE = 'complete';
const WIDTH_TOLERANCE = 1;
const SPACING_TOLERANCE = 0.0005;

export class BC_AutoTextFitElement {
  constructor($element) {
    this.$element = $element;
    this.targetWidth = $element.width();
    this.restartFit();
    const oldSpacing = +this.$element.css('letter-spacing').replace('px', '');
    this.tryingSpacing = oldSpacing;

    // no actual text? don't waste time on it
    if ($element.text().trim() === '') {
      this.markComplete();
    }
    this.tryCurrentSpacing();
  }

  setState(newState) {
    this.state = newState;
    this.$element.data('fitting-state', this.state);
  }

  restartFit() {
    this.targetWidth = this.$element.width();
    const existingFit = this.$element.data(`spacing-for-${this.targetWidth}`);
    if (existingFit) {
      // console.log(`Sizing already found for target ${this.targetWidth}: ${existingFit}`);
      this.tryingSpacing = existingFit;
      this.tryCurrentSpacing();
      this.markComplete();
    }

    this.min = -2;
    this.max = 15;
    this.setState(STATE_READY);
    this.$element.css('opacity', 0.1);
    // this.$element.css('background-color', '#f00');
  }

  tryCurrentSpacing() {
    // console.log(`tryCurrentSpacing(${this.tryingSpacing})px`);
    this.$element.css('letter-spacing', `${this.tryingSpacing}px`);
  }

  isReady() {
    return this.state === STATE_READY;
  }
  isComplete() {
    return this.state === STATE_COMPLETE;
  }

  /// Utility function: Find out actual rendered width of text
  actualTextWidth(jq_obj) {
    const html_org = jq_obj.html();
    const html_calc = '<span>' + html_org + '</span>';
    jq_obj.html(html_calc);
    const width = jq_obj.find('span:first').width();
    jq_obj.html(html_org);
    return width;
  }

  // Always returns true
  markComplete() {
    // console.log(`markComplete(${this.tryingSpacing})px`);
    this.setState(STATE_COMPLETE);
    this.$element.css('opacity', 1);
    if (!this.$element.data(`spacing-for-${this.targetWidth}`)) {
      this.$element.data(`spacing-for-${this.targetWidth}`, this.tryingSpacing);
    }
    return true;
  }

  /// Take a single step towards optimal text fit.
  /// Returns true if completed
  step() {
    if (this.state === STATE_COMPLETE) {
      return true;
    }

    const width = this.actualTextWidth(this.$element);
    // console.log(`Stepping text: ${this.$element.text()}`);
    // console.log(`-> width=${width}, targetWidth = ${this.targetWidth}`);
    if (Math.abs(this.targetWidth - width) < WIDTH_TOLERANCE || this.max - this.min < SPACING_TOLERANCE) {
      return this.markComplete();
    }

    // Adjustment is needed, which direction?
    if (width > this.targetWidth) {
      this.max = this.tryingSpacing;
    } else if (width < this.targetWidth) {
      this.min = this.tryingSpacing;
    }
    this.tryingSpacing = (this.min + this.max) / 2;
    this.tryCurrentSpacing();

    return false;
  }
}
