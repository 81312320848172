import { BC_Slide } from "./bc-slide";

export class BC_Slide_Enquiries extends BC_Slide {
  // constructor(preloader, autoTextFitter, avTools, settings) {
  //   super(preloader, autoTextFitter, avTools, settings);
  // }

  onReady(slideshow) {
    super.onReady(slideshow);
    // Click "Enquiries" button
    $(`${this.getSelector()} .enquiries`).click(() => {
      this.slideshow.showSlide(this.slideshow.ActiveRow, 1);
    });
    $(`${this.getSelector()} .phone`).click(() => {
      window.open("tel:+61450996570");
    });
    $(`${this.getSelector()} .email`).click(() => {
      window.open("mailto:info@botanicalcuisine.com");
    });
  }
}
