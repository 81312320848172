import { BC_Slide } from './bc-slide';

export class BC_Slide_ProductDetail extends BC_Slide {
  setProduct(product) {
    console.log('View ' + product.name1);
    // Set up product sheet
    this.setTextElement('.insert-name1-here', product.name1);
    this.setTextElement('.insert-name2-here', product.name2);
    this.setTextElement('.insert-product-code-here', product.code);
    this.setTextElement('.insert-grams-here', product.grams);

    const slideSelector = this.getSelector();
    this.autoTextFitter.autoFitChildrenLetterSpacing(slideSelector);
    $(`${slideSelector} .side-view`).attr('src', `img/products/${product.slug}-side.png`);
    $(`${slideSelector} .top-view`).attr('src', `img/products/${product.slug}-top.png`);
    $(`${slideSelector} .bottom-view`).attr('src', `img/products/${product.slug}-bottom.png`);
  }
}
