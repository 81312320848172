import { BC_Cooldown } from "./bc-cooldown";

const AUTO_SCROLL_STATE_STILL = 0;
const AUTO_SCROLL_STATE_WAITING = 1;
const AUTO_SCROLL_STATE_GOING_UP = 2;
const AUTO_SCROLL_STATE_GOING_DOWN = 3;

const SCROLL_METHOD_INTERNAL = "internal";
const SCROLL_METHOD_FULLPAGE_JS = "fullpage";
// const SCROLL_METHOD = SCROLL_METHOD_INTERNAL;
const SCROLL_METHOD = SCROLL_METHOD_FULLPAGE_JS;

export class BC_Slideshow {
  constructor(slideset, autoTextFitter, preloader, avTools, displayController) {
    this.slideset = slideset;
    this.autoTextFitter = autoTextFitter;
    this.preloader = preloader;
    this.avTools = avTools;
    this.displayController = displayController;
    this.scrollingWasBasedOnOrientation = window.orientation;

    this.ActiveRow = 0;
    this.ActiveColumn = 0;

    if (SCROLL_METHOD == SCROLL_METHOD_INTERNAL) {
      this.LastScrollTime = $.now();
      this.LastScrollTop = 0;
      this.userScrollingCooldown = new BC_Cooldown(500, () =>
        this.startAutoScrollToActiveRow()
      );
      this.autoScrollState = AUTO_SCROLL_STATE_STILL;
      this.autoScrollingToTop = 0;
    }
  }

  // Returns the slide at a specified position
  slideAt(row, col) {
    return this.slideset[row][col];
  }

  activeSlide() {
    return this.slideset[this.ActiveRow][this.ActiveColumn];
  }

  // on document ready
  onReady() {
    // trigger document ready for each slide
    for (let row = 0; row < this.slideset.length; row++) {
      for (let col = 0; col < this.slideset[row].length; col++) {
        this.slideAt(row, col).onReady(this);
      }
    }
    if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
      $(window).scroll((event) => this.onUserScroll(event));
      setInterval(() => {
        if (
          this.autoScrollState === AUTO_SCROLL_STATE_GOING_DOWN ||
          this.autoScrollState === AUTO_SCROLL_STATE_GOING_UP
        ) {
          this.doAutoScroll();
        }
      }, 10);

      window.onresize = () => this.handleViewResize();
      this.displayController.setHeightChangeCallback((multiplier) =>
        this.handleVCHeightChange(multiplier)
      );
    }
    if (SCROLL_METHOD === SCROLL_METHOD_FULLPAGE_JS) {
      this.fullpage = new fullpage("#rows-container", {
        autoScrolling: true,
        // scrollHorizontally: true,
        scrollOverflow: false,
        scrollOverflowOptions: {
          click: false,
          preventDefaultException: { tagName:/.*/ }
        },
        lazyLoading: false,
        licenseKey: "6EE80AD3-E1CD4719-BE797657-9302F78F",
        afterLoad: (origin, _destination, direction) => {
          console.log(`Load row ${origin.index} direction ${direction}`);

          let delta = 0;
          if (direction === "up") {
            delta = -1;
          }
          if (direction === "down") {
            delta = 1;
          }

          let newRow = this.validatedRow(origin.index + delta);
          this.slideAt(newRow, 0).prepareForOnscreen(this);
          console.log("Calling this.showSlide from handler");
          this.showSlide(newRow, 0);
          setTimeout(() => {
            this.slideAt(
              this.validatedRow(origin.index + delta + delta),
              0
            ).prepareForOnscreen(this);
          });
        },
      });
    }
  }

  handleViewResize() {
    if (this.displayController.isZoomed) {
      return;
    }
    if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
      if (this.scrollingWasBasedOnOrientation != window.orientation) {
        this.LastScrollTop = window.innerHeight * this.ActiveRow;
        $(window).scrollTop(this.LastScrollTop);
        this.scrollingWasBasedOnOrientation = window.orientation;
      } else {
        this.userScrollingCooldown.activate();
        this.triggerAutoScrollNowOrAfterUserScroll();
      }
      this.prepareScrolledSlidesForScreen();
    }
  }

  handleVCHeightChange(multiplier) {
    this.LastScrollTop *= multiplier;
    this.triggerAutoScrollNowOrAfterUserScroll();

    //    $(window).scrollTop($(window).scrollTop() * multiplier);
  }

  triggerAutoScrollNowOrAfterUserScroll() {
    if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
      //     console.log("triggerAutoScrollNowOrAfterUserScroll");
      if (this.userScrollingCooldown.isActive()) {
        return;
      } // already attached to end of cooldown
      this.startAutoScrollToActiveRow();
    }
  }

  startAutoScrollToActiveRow() {
    if (this.displayController.isZoomed) {
      return;
    }
    if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
      const scrollTop = $(window).scrollTop();
      this.autoScrollingToTop = window.innerHeight * this.ActiveRow;
      /*     console.log */ `-> startAutoScrollToActiveRow, scrollTop = ${scrollTop}, autoScrollingToTop=${this.autoScrollingToTop}, ActiveRow = ${this.ActiveRow}`;
      if (scrollTop > this.autoScrollingToTop) {
        this.autoScrollState = AUTO_SCROLL_STATE_GOING_UP;
      } else if (scrollTop < this.autoScrollingToTop) {
        this.autoScrollState = AUTO_SCROLL_STATE_GOING_DOWN;
      } else {
        this.autoScrollState = AUTO_SCROLL_STATE_STILL;
      }
      this.LastScrollTop = scrollTop;
      //     this.autoScrollingCooldown.activate();
    }
  }

  doAutoScroll() {
    if (this.displayController.isZoomed) {
      return;
    }
    if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
      if (
        this.autoScrollState == AUTO_SCROLL_STATE_STILL ||
        this.autoScrollState == AUTO_SCROLL_STATE_WAITING
      ) {
        return;
      }

      const scrollTop = $(window).scrollTop();

      // User forced change in direction?
      if (
        this.autoScrollState == AUTO_SCROLL_STATE_GOING_DOWN &&
        scrollTop < this.LastScrollTop
      ) {
        this.autoScrollState = AUTO_SCROLL_STATE_WAITING;
        this.userScrollingCooldown.activate();
        return;
      }
      if (
        this.autoScrollState == AUTO_SCROLL_STATE_GOING_UP &&
        scrollTop > this.LastScrollTop
      ) {
        this.autoScrollState = AUTO_SCROLL_STATE_WAITING;
        this.userScrollingCooldown.activate();
        return;
      }

      // Still on track
      const diff = Math.floor(this.autoScrollingToTop - scrollTop);
      if (diff != 0) {
        const moveBy = diff > 20 || diff < -20 ? diff / 20 : diff < 0 ? -1 : 1;
        $(window).scrollTop(scrollTop + moveBy);
      } else {
        this.autoScrollState = AUTO_SCROLL_STATE_STILL;
      }
      this.LastScrollTop = scrollTop;
    }
  }

  prepareScrolledSlidesForScreen() {
    const scrollTop = $(window).scrollTop();
    const rowFloat = scrollTop / window.innerHeight;
    const lowRow = this.validatedRow(Math.floor(rowFloat));
    const highRow = this.validatedRow(Math.ceil(rowFloat));
    this.slideAt(lowRow, 0).prepareForOnscreen(this);
    this.slideAt(highRow, 0).prepareForOnscreen(this);
  }

  onUserScroll(_event) {
    if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
      this.prepareScrolledSlidesForScreen();

      const scrollTop = $(window).scrollTop();
      const rowFloat = scrollTop / window.innerHeight;
      const scrolledRow = this.validatedRow(Math.round(rowFloat));

      if (scrollTop != this.LastScrollTop) {
        if (
          this.autoScrollState == AUTO_SCROLL_STATE_GOING_DOWN ||
          this.autoScrollState == AUTO_SCROLL_STATE_GOING_UP
        ) {
          return;
        }

        this.autoScrollState == AUTO_SCROLL_STATE_WAITING;
        this.userScrollingCooldown.activate(); // re-activate if more user scrolling
        if (scrolledRow != this.ActiveRow) {
          this.showSlide(scrolledRow, 0);
        }
      }
    }
  }

  isValueBetween(value, bound1, bound2) {
    if (bound1 <= bound2) {
      return value >= bound1 && value <= bound2;
    } else {
      return value >= bound2 && value <= bound1;
    }
  }

  // Compare indexes, return -1 or 0 or 1 according to < or == or >
  cmp(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  /// Change position of slides to be above
  /// the page (-100vh), on the page (0) or
  /// below the page (100vh)
  /// Vertical scrolling happens automatically
  moveSlideIntoView(showRow, showCol) {
    this.slideAt(showRow, showCol).prepareForOnscreen(this);
    if (SCROLL_METHOD === SCROLL_METHOD_FULLPAGE_JS) {
      this.startAutoScrollToActiveRow();
      this.fullpage.setAllowScrolling(showCol === 0);
      this.fullpage.setKeyboardScrolling(showCol === 0);
    }
    // console.log(`moveSlideIntoView(${showRow}, ${showCol})`);
    $(".slide-row").each((row, element) => {
      const jqRow = $(element);

      let colForThisRow = showCol;
      jqRow.toggleClass("active", row === showRow);
      if (row !== showRow) {
        colForThisRow = 0;
      }
      // console.log(`in moveSlideIntoView, checking row ${row} want to show row ${showRow}`);
      jqRow.find(".slide-column").each((col, element) => {
        const jqColumn = $(element);
        // console.log(`in moveSlideIntoView, checking col ${col} want to show row ${colForThisRow}`);
        const horizontal = this.cmp(col, colForThisRow) * 100;
        //if (SCROLL_METHOD === SCROLL_METHOD_INTERNAL) {
        jqColumn.css({
          "-webkit-transform": "translateX(" + horizontal + "vw)",
          "-ms-transform": "translateX(" + horizontal + "vw)",
          transform: "translateX(" + horizontal + "vw)",
        });
        // }
        if (col === colForThisRow && row === showRow) {
          jqColumn.addClass("active");
        } else {
          jqColumn.removeClass("active");
        }
      });
    });
    $("#pagination span").each((row, element) => {
      $(element).toggleClass("active", row === showRow);
    });
  }

  /// Given a row number the user has requested,
  /// return the nearest VALID row number
  validatedRow(row) {
    const max = this.slideset.length - 1;
    return row < 0 ? 0 : row > max ? max : row;
  }

  /// Given a column number the user has requested,
  /// return the nearest VALID column number
  validatedCol(safeRow, col) {
    const max = this.slideset[safeRow].length - 1;
    return col < 0 ? 0 : col > max ? max : col;
  }

  hideMagnifier() {
    setTimeout(() => {
      $("#glass").css("display", "none");
      $("body").css("cursor", "default");
    }, 500);
  }

  /// Given a row & column number, move that
  /// slide into view and make it the current slide
  showSlide(newRow, newCol) {
    console.log(`showSlide(${newRow}, ${newCol})`);
    if (this.displayController.isZoomed) {
      return;
    }

    this.hideMagnifier();
    let zoom = document.documentElement.clientWidth / window.innerWidth;

    // Check conditions for moving up
    if (newRow < this.ActiveRow) {
      if (this.ActiveColumn > 0 || newRow < 0 || zoom > 1) {
        this.activeSlide().bounce("20px");
        return;
      }
    }
    // Check conditions for moving down
    if (newRow > this.ActiveRow) {
      if (this.ActiveColumn > 0 || newRow >= this.slideset.length || zoom > 1) {
        this.activeSlide().bounce("-20px");
        return;
      }
    }
    newRow = this.validatedRow(newRow);
    newCol = this.validatedCol(newRow, newCol);
    //     console.log(`Set slide ${newRow}, ${newCol}`);
    const slide = this.slideAt(newRow, newCol);
    slide.preloadImages().then(() => {
      // console.log(`Slide ${newRow}, ${newCol} preload returned`);
      $("body").addClass("preload-ready");
      if (this.ActiveRow !== newRow) {
        // reset this row to 0 first
        this.moveSlideIntoView(this.ActiveRow, 0);
      }
      this.ActiveRow = newRow;
      this.ActiveColumn = newCol;
      this.moveSlideIntoView(this.ActiveRow, this.ActiveColumn);
      // console.log(` -> preload returned 5`);
    });
  }

  showNextSlide() {
    this.showSlide(this.ActiveRow + 1, 0);
  }

  showPrevSlide() {
    this.showSlide(this.ActiveRow - 1, 0);
  }

  setupProductClickHandlers() {
    for (let row = 0; row < this.slideset.length; row++) {
      for (let col = 0; col < this.slideset[row].length; col++) {
        this.slideAt(row, col).setupClickHandlers((product) => {
          this.slideAt(row, col + 1).fillProductInfoSheet(product);
          this.showSlide(row, col + 1);
        });
      }
    }
  }
}
