import { BC_Slide } from "./bc-slide";

export class BC_Slide_Enquiries_Thanks extends BC_Slide {
  constructor(preloader, autoTextFitter, avTools, displayController, settings) {
    super(preloader, autoTextFitter, avTools, displayController, settings);
    //   this.EmailSentMsg =
    //     'Your email has been<br/>' +
    //     'successfully sent.<br/><br/>' +
    //     'Thank you for your interest.<br/><br/>' +
    //     'We will do our best to<br/>' +
    //     'respond to you within<br/>' +
    //     '24 hours.';
  }

  submitEnquiryForm(formInfo) {
    const selector = this.getSelector();
    $(selector).toggleClass("success", false);
    const $output = $(`${selector} .text`);

    console.error("submitEnquiryForm called");
    $output.html("Sending email...");
    $.post(
      "enquiry.php",
      {
        subject: "Website Enquiry Form",
        message: `Enquiry From ${formInfo.name} <${formInfo.email}>
    
    Company: ${formInfo.company}
    Phone: ${formInfo.phone}
    City-State-Country: ${formInfo.city_state_country}
    Message: ${formInfo.what_we_can_do_for_you}
          `,
      },
      (data) => {
        try {
          const dataObj = JSON.parse(data);
          if (!dataObj.ok || dataObj.error) {
            console.error("Unable to send but got coherent error back:");
            console.error(data);
            $output.html("Unable to send: <br> " + dataObj.error);
          } else {
            console.log("Success: ", data);
            $output.html("");
            $(selector).toggleClass("success", true);
          }
        } catch (err) {
          console.log(data);
          console.error(err);
          $output.html("Unable to send: <br/>Unexpected reply from server.");
        }
      }
    ).fail((err) => {
      console.error(err);
      let msg = "";
      if (err && err.status) {
        msg = `${err.status}: ${err.statusText}`;
      }
      $output.html("Unable to send email.<br/><br/>" + msg);
      //   $output.html(this.EmailSentMsg);
    });
  }
}
