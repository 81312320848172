import { BC_AutoTextFitElement } from './bc-auto-text-fit-element';

export class BC_AutoTextFitter {
  constructor() {
    // List of  text fit processes currently running
    this.ActiveFits = [];
    this.interval = null;
  }

  startInterval() {
    if (!this.interval) {
      this.interval = setInterval(() => this.step(), 20);
    }
  }

  stopInterval() {
    if (this.interval && this.ActiveFits.length == 0) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  step() {
    //     console.log(`ACTIVE FITS = ${this.ActiveFits.length}`);
    if (this.ActiveFits.length === 0) {
      this.stopInterval();
    }
    this.ActiveFits = this.ActiveFits.filter((fit) => !fit.step());
  }

  removeFromAutoFits(elementList) {
    for (let i = 0; i < elementList.length; i++) {
      this.ActiveFits = this.ActiveFits.filter((fit) => fit.element.is(elementList[i]));
    }
  }

  /// Start autofitting text contained within the given
  /// top level container and in a .autofit classed div/span
  autoFitChildrenLetterSpacing(topLevel) {
    //     this.clearAutoFits();
    $(`${topLevel} .autofit-spacing`).each((_index, element) => {
      this.triggerSpacingFit($(element));
    });
  }

  // Trigger a new fit for a specific element
  triggerSpacingFit($element) {
    setTimeout(() => {
      const matches = this.ActiveFits.filter((fit) => fit.$element.is($element));
      if (matches.length == 0) {
        this.ActiveFits.push(new BC_AutoTextFitElement($element));
        this.startInterval();
      } else {
        matches.forEach((fit) => fit.restartFit());
      }
    });
  }

  /// Start autofitting text contained within the given
  /// top level container and in a .autofit classed div/span
  autoHeightFitChildren(topLevel) {
    //     this.clearAutoFits();
    $(`${topLevel} .autofit-height`).each((_index, element) => {
      this.fitHeightToContainer($(element));
    });
  }

  fitHeightToContainer($element) {
    setTimeout(() => {
      const wantHeight = $element.height();
      let lines = $element.data('lines');
      let lineheight = $element.data('lineheight');
      if (!lines) {
        lines = 1;
      }
      if (!lineheight) {
        lineheight = 1;
      }
      const fontsize = `${wantHeight / lines}px`;
      console.log(`fit height lines=${lines}, fontsize = ${fontsize}`);
      $element.css('line-height', lineheight);
      $element.css('font-size', fontsize);
      // console.log(`Fit height for ${$element.text()} to ${wantHeight}, element height is ${$element.height()}`);
      // $element.css('border-left', `1px solid green`);
    });
  }

  // fitChildren($element) {
  //   if (this.ActiveFits.length == 0) {
  //     console.log(`- fitChildren of: ${$element}`);
  //     this.autoHeightFitChildren($element);
  //     this.autoFitChildrenLetterSpacing($element);
  //   } else {
  //     setTimeout(() => {
  //       this.fitChildren($element);
  //     }, 500);
  //   }
  // }
}
