export class BC_AVTools {
  /// Utility function: Play an embedded audio file
  playAudio(elementId) {
    // let audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    const sound = document.getElementById(elementId);
    sound.currentTime = 0;

    const source1 = document.getElementById('click-audio-src1');
    const src1 = source1.src;
    source1.src = '';

    const source2 = document.getElementById('click-audio-src2');
    const src2 = source2.src;
    source2.src = '';

    setTimeout(() => {
      source1.src = src1;
      source2.src = src2;
      const promise = sound.play();
      if (promise !== undefined) {
        promise.catch((error) => {
          // Audio play was blocked, usually due to lack of user interaction to trigger it
          // TODO: Check planned behaviour on safari which blocks audio without user interaction
          console.error(error);
          console.error('TODO: Discuss workarounds for blocked audio');
        });
      }
    }, 1);
  }
}
