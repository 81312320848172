import { BC_Slide } from "./bc-slide";

export class BC_Slide_Enquiries_Form extends BC_Slide {
  constructor(preloader, autoTextFitter, avTools, displayController, settings) {
    super(preloader, autoTextFitter, avTools, displayController, settings);
  }

  onReady(slideshow) {
    const selector = this.getSelector();
    const $textArea = $(`${selector} textarea[name=what-we-can-do-for-you]`);
    $textArea.on("keyup input", (event) => {
      this.adjustTextAreaHeight($(event.currentTarget));
    });

    // another attempt to prevent forms zooming in on iPhone
    // setTimeout(() => {
    //   $(
    //     `${selector} input, ${selector} textarea, ${selector} input[type=submit]`
    //   ).each(function () {
    //     const $this = $(this);
    //     if (parseInt($this.css("fontSize")) < 50) {
    //       $this.css({ "font-size": "50px" });
    //     }
    //   });
    // }, 1000);

    $(`${this.getSelector()} form`).submit((e) => {
      e.preventDefault();
      slideshow.showSlide(slideshow.ActiveRow, 2);
      const formInfo = {
        name: $(`${selector} input[name=name]`).val(),
        company: $(`${selector}  input[name=company]`).val(),
        email: $(`${selector}  input[name=email]`).val(),
        phone: $(`${selector}  input[name=phone]`).val(),
        city_state_country: $(
          `${selector}  input[name=city-state-country]`
        ).val(),
        what_we_can_do_for_you: $(
          `${selector} input[name=what-we-can-do-for-you]`
        ).val(),
      };
      slideshow.slideAt(slideshow.ActiveRow, 2).submitEnquiryForm(formInfo);
    });
  }

  prepareForOnscreen() {
    const $textArea = $(
      `${this.getSelector()} textarea[name=what-we-can-do-for-you]`
    );
    this.adjustTextAreaHeight($textArea);
  }

  adjustTextAreaHeight(textarea) {
    const selector = `${this.getSelector()} .content-aspect-ratio`;
    let slideHeightPx,
      submitButtonTopAtRest,
      lineheight,
      maxheight = 0;
    if (this.displayController.aspect === this.displayController.PORTRAIT) {
      slideHeightPx = $(selector).height();
      submitButtonTopAtRest = (987 / 1400) * slideHeightPx;
      lineheight = (22 / 1400) * slideHeightPx;
      maxheight = 90;
    } else {
      slideHeightPx = $(selector).height();
      submitButtonTopAtRest = (797.2 / 1000) * slideHeightPx;
      lineheight = (19.4 / 1000) * slideHeightPx;
      maxheight = 90;
    }
    textarea.css("height", "auto");
    let textheight = textarea.prop("scrollHeight") - lineheight;
    if (textheight > maxheight) {
      textheight = maxheight;
    }
    console.log(`lineheight: ${lineheight} textheight: ${textheight}`);
    textarea.height(textheight + lineheight);
    // $(`${selector} .contact-form-submit`).css({ height: `${slideHeightPx + textheight}px` });
    const submitButtonTop = submitButtonTopAtRest + textheight;
    console.log(
      `>>> Submit button top ${submitButtonTop}, at rest = ${submitButtonTopAtRest}, testheight = ${textheight}`
    );
    $(`${selector} input[name=submit]`).css({ top: `${submitButtonTop}px` });
    $(`${selector} .contact-form-portrait-submit`).css({
      top: `${textheight}px`,
    });
    $(`${selector} .contact-form-landscape-submit`).css({
      top: `${textheight}px`,
    });
  }
}
