import { BC_Slide } from './bc-slide';

export class BC_Slide_ProductGrid extends BC_Slide {
  onReady(slideshow) {
    super.onReady(slideshow);
    // Fill products list
    const $grid = $(`${this.getSelector()} .grid`);
    this.products.forEach((product) => {
      const slug = product.slug;
      const img = `<img class="preload ${slug}">`;
      $grid.append(`<div id='view-product-${slug}'>${img}</div>`);
      this.preloads[slug] = `img/products/${slug}-small.svg`;
    });
    setTimeout(() => {
      this.products.forEach((product) => {
        const slug = product.slug;
        console.log(`Adding event to #view-product-${slug}`, $(`#view-product-${slug}`));
        $(`#view-product-${slug}`).click(() => {
          this.showProduct(product);
        });
      });
    });
  }

  showProduct(product) {
    this.slideshow.slideAt(this.slideshow.ActiveRow, 1).setProduct(product);
    this.slideshow.showSlide(this.slideshow.ActiveRow, 1);
  }
}
